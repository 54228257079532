import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { AppBar, Box, Button, styled, Toolbar } from "@mui/material";

import "./App.scss";
import Typography from "@mui/material/Typography";
import Properties from "./pages/Properties";
import ContactUs from "./pages/ContactUs";

const StyledToolbar = styled(Toolbar)({
  color: "white",
  backgroundColor: "darkgray",
  boxShadow: "0 4px 2px -2px gray",
});

function App() {
  return (
    <div className="App">
      <AppBar position="sticky">
        <StyledToolbar disableGutters>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            width="100%"
            alignItems="center"
          >
            <img
              className="logo-icon"
              src={require("./1201_42315_MS201_Web.jpg")}
              alt="logo"
            />
            <Button
              sx={{ color: "white", textTransform: "unset !important" }}
              variant="text"
              href="/"
            >
              Home
            </Button>
            <Button
              sx={{ color: "white", textTransform: "unset !important" }}
              variant="text"
              href="/properties"
            >
              Properties
            </Button>
            <Typography>About Us</Typography>
            <Button
              sx={{ color: "white", textTransform: "unset !important" }}
              variant="text"
              href="/contact"
            >
              Contact Us
            </Button>
          </Box>
        </StyledToolbar>
      </AppBar>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
