import { Box } from "@mui/material";

const Properties = () => {
  return (
    <Box display="flex" justifyContent="center" height="90vh">
      <iframe
        height="100%"
        width="100%"
        title="flexMLS"
        src="https://my.flexmls.com/my_portal12/search/idx_links/20090225212813979556000000/listings"
      ></iframe>
    </Box>
  );
};
export default Properties;
