import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Background from "../photos/fakehome2.jpg";

const Home = () => {
  return (
    <Box>
      <Box
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          height: "100vh",
        }}
        display="flex"
        flexDirection={"column"}
        alignItems="center"
      >
        <Typography sx={{ paddingTop: "5rem" }} variant="h3">
          Welcome To Hearts And Home Realty LLC
        </Typography>
      </Box>
    </Box>
  );
};
export default Home;
